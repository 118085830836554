
form[name=shop-switch-form] {
  display: flex;
}
form[name=shop-switch-form] > div {
  display: flex;
  margin-bottom: 0;
  flex-grow:1;
}
form[name=shop-switch-form] > div > label {
  margin-right:10px;
  line-height: 36px;
  margin-bottom: 0px;
}
form[name=shop-switch-form] > div > div {
  flex-grow:1;
}
form[name=shop-switch-form] > button {
  margin-left:10px;
}



/*
.address .table td.header {
    padding-right:15px;
}

.address .table td {
    border-top:0px;
    white-space: nowrap;
    padding:0px;
}
*/



.shippingHistoryCollapse > a i:last-child {
    margin-left:5px;
    transition: 0.3s ease all;
}
.shippingHistoryCollapse > a.opened i:last-child {
    transform: rotate(180deg);
}



.promo-codes {
    margin-bottom: 20px;
}
.promo-codes .applied-codes {
    margin-top: 20px;
}
.promo-codes .applied-codes span.code {
   font-weight: 600;
   color: #28a745
}
.promo-codes .applied-codes span.note {
    margin-left:10px;
}



.button.payment {
  width: 100%;
}



.review-order hr {
  border-top: 2px solid rgba(0,0,0,.3);
  margin-left: -20px;
  margin-right: -20px;
}
.review-order td.header {
  white-space: nowrap;
}



.prepare-order hr {
  border-top: 2px solid rgba(0,0,0,.3);
  margin-left: -20px;
  margin-right: -20px;
}
.prepare-order td.header {
  white-space: nowrap;
}
.prepare-order .tracking-number {
  margin-bottom:5px;
}



.prepare-order hr {
  border-top: 2px solid rgba(0,0,0,.3);
  margin-left: -20px;
  margin-right: -20px;
}
.prepare-order td.header {
  white-space: nowrap;
}



.check-delivery hr {
  border-top: 2px solid rgba(0,0,0,.3);
  margin-left: -20px;
  margin-right: -20px;
}
.check-delivery td.header {
  white-space: nowrap;
}
.check-delivery .tracking-number {
  margin-bottom:5px;
}



.dropdown-list .top-dropdown-list, .dropdown-list .item-checkbox {
  margin:0px;
}
.dropdown-list .top-dropdown-list input {
  vertical-align: middle;
  margin-right: 3px;
}
.dropdown-list .top-dropdown-list span {
  vertical-align: middle;
}
.dropdown-list .item-checkbox input {
  vertical-align: middle;
}
.dropdown-list .item-checkbox span {
  vertical-align: middle;
}


.col-auto.buttons[data-v-11f181f2] {
  text-align: right;
  display:flex;
}
.col-auto.buttons > div[data-v-11f181f2] {
  margin:5px;  /* and that, will result in a 10px gap */
}
.isDelivered[data-v-11f181f2] {
  font-weight: bold;
  color: #FF0000;
}

